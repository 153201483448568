
import { defineComponent } from "vue-demi";
import CommonInputs from '@/components/common/inputs/index.vue';
import { CERTIFICATE_INPUTS } from "@/store/common/catalog";
import { useAPI } from "@/use";

export default defineComponent({
  name: 'CatalogCertificate',
  data() {
    return {
      certificate: CERTIFICATE_INPUTS
    }
  },
  methods: {
    async submit() {
      // TODO: Для всех полей делать проверку
      const result = await useAPI().certificate.createMethod(this.certificate.data);
      this.notification('Через несколько секунд Вы будете перенаправлены на оплату подарочной карты!', 'success');
      setTimeout(() => {
        this.redirect(result.data.payment.payment_url);
      }, 6000);
    },
    redirect(payment_url: string) {
      location.replace(payment_url);
    },
    notification(message: string, status: string) {
      return this.$store.commit('createNotification', {
        message, status
      });
    },
  },
  components: {
    CommonInputs
  }
})
