import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-43c73f56"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "product--image flex-center" }
const _hoisted_2 = {
  key: 0,
  alt: "Product Image"
}
const _hoisted_3 = { class: "product--container flex-column" }
const _hoisted_4 = { class: "name als" }
const _hoisted_5 = { class: "weight als" }
const _hoisted_6 = { class: "product--footer flex-center-between" }
const _hoisted_7 = { class: "price leksa" }
const _hoisted_8 = { class: "cart" }
const _hoisted_9 = {
  key: 1,
  class: "cart--container flex items-center"
}
const _hoisted_10 = { class: "quantity leksa_title fz22" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_empty_image_icon = _resolveComponent("empty-image-icon")!
  const _component_shop_cart_icon = _resolveComponent("shop-cart-icon")!
  const _component_minus_icon = _resolveComponent("minus-icon")!
  const _component_plus_icon = _resolveComponent("plus-icon")!
  const _directive_lazy = _resolveDirective("lazy")!

  return (_openBlock(), _createElementBlock("div", {
    class: "product flex-column cursor",
    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push('/catalog/product/' + _ctx.product._id)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.product.image && _ctx.product.image[0])
        ? _withDirectives((_openBlock(), _createElementBlock("img", _hoisted_2, null, 512)), [
            [_directive_lazy, _ctx.product.image[0].src]
          ])
        : (_openBlock(), _createBlock(_component_empty_image_icon, { key: 1 }))
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.product.name) + ", " + _toDisplayString(_ctx.currentType) + " шт", 1),
      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.product.length) + "x" + _toDisplayString(_ctx.product.width) + " мм, " + _toDisplayString(_ctx.product.weight) + " г", 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.currentPrice) + " ₽", 1),
      _createElementVNode("div", _hoisted_8, [
        (!_ctx.cartProduct)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "cart--block empty",
              onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addToCart && _ctx.addToCart(...args)), ["stop"]))
            }, [
              _createVNode(_component_shop_cart_icon, { color: "#fff" })
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createElementVNode("div", {
                class: "cart--block flex-center inc",
                onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (_ctx.toggleProductCart(-1)), ["stop"]))
              }, [
                _createVNode(_component_minus_icon)
              ]),
              _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.cartProduct.quantity), 1),
              _createElementVNode("div", {
                class: "cart--block flex-center inc",
                onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.toggleProductCart(1)), ["stop"]))
              }, [
                _createVNode(_component_plus_icon)
              ])
            ]))
      ])
    ])
  ]))
}