
import { Product } from "@/interfaces/product/product.dto";
import { CATALOG_FILTER_SORT_LIST } from "@/store/common/drop";
import { useAPI } from "@/use";
import { defineComponent } from "vue-demi";
import CommonProduct from "./index.vue";
import Preloader from '@/components/common/preloader/index.vue';
import { Cart } from "@/interfaces/cart/cart.dto";

export default defineComponent({
  name: "ProductList",
  data() {
    return {
      filters: {
        category: "",
        sort: "",
      },
      total: 0,
      query: {
        category: "",
        sort: "",
        limit: 8,
        page: 1,
      },
      drop: {
        category: {
          id: "catalog-filters-category-action",
          list: [] as { _id: string; name: string }[],
          border: true,
          width: 200,
        },
        sort: {
          id: "catalog-filters-sort-action",
          list: CATALOG_FILTER_SORT_LIST,
          border: true,
          width: 200,
        },
      },
      products: null as Array<Product.Dto> | null,
      cartProducts: [] as Array<Cart.Product>,
    };
  },
  created() {
    this.getProductListMethod();
    this.getCartProductListMethod();
    this.getCategoryAllListMethod();
  },
  mounted() {
    this.onEmits();
  },
  unmounted() {
    this.offEmits();
  },
  methods: {
    currentCartProduct(product: string) {
      return this.cartProducts.find(p => p.product._id === product);
    },
    async pagination() {
      this.query.page += 1;
      const result = await this.API.catalog.getProductListMethod(this.query);
      this.products = [...this.products as Array<Product.Dto>, ...result.data];
    },
    async getProductListMethod() {
      const result = await this.API.catalog.getProductListMethod(this.query);
      this.products = result.data;
      this.total = result.total;
    },
    async getCartProductListMethod() {
      const result = await useAPI().cart.getMethod();
      this.cartProducts = result.data?.products || [];
    },
    async getCategoryAllListMethod() {
      const result = await useAPI().catalog.getCategoryAllListMethod();
      this.drop.category.list = [{ _id: "", name: "Все" }, ...result.data];
    },
    async toggleProductCart(product: string, inc: number) {
      console.log(product, inc);
      
      const result = await useAPI().cart.toggleProductMethod(product, inc);
      if (!this.$store.getters.user)
        this.$store.commit('updateUserAuthorization', result.data.uuid);
      this.cartProducts = result.data.products;
      this.$store.commit('setProductLength', result.data.products.reduce((acc: number, p: { quantity: number }) => acc + p.quantity, 0));
    },
    showDrop(dropType: "category" | "sort") {
      this.$store.commit("createDropElement", {
        options: this.drop[dropType],
        target: `.filters .${dropType}`,
      });
    },
    // Emitter
    onEmits() {
      this.emitter.on(
        `catalog-filters-category-action-drop`,
        this.selectDropElement
      );
      this.emitter.on(
        `catalog-filters-sort-action-drop`,
        this.selectDropSortElement
      );
    },
    offEmits() {
      this.emitter.off(
        `catalog-filters-category-action-drop`,
        this.selectDropElement
      );
      this.emitter.on(
        `catalog-filters-sort-action-drop`,
        this.selectDropSortElement
      );
    },
    selectDropElement(element: { _id: string; name: string }) {
      this.query.category = element._id;
      this.filters.category = element.name === "Все" ? "" : element.name;
      this.query.page = 1;
      this.getProductListMethod();
      this.$store.commit("destroyDropElement");
    },
    selectDropSortElement(element: { _id: string; name: string }) {
      this.query.sort = element._id;
      this.filters.sort = element.name === "Отменить" ? "" : element.name;
      this.query.page = 1;
      this.getProductListMethod();
      this.$store.commit("destroyDropElement");
    },
  },
  components: {
    CommonProduct,
    Preloader
  },
});
