
import { defineComponent } from "vue-demi";
import CatalogCertificate from './modules/certificate/index.vue';
import CatalogProducts from './modules/product/List.vue';

export default defineComponent({
  name: 'MainCatalogMain',
  components: {
    CatalogCertificate,
    CatalogProducts
  }
})
