
import { defineComponent, PropType } from "vue-demi";
import { Product } from "@/interfaces/product/product.dto";
import { EmptyImageIcon, ShopCartIcon, PlusIcon, MinusIcon } from '@/plugins/icons';
import { PRODUCT_TYPES_NAMES } from "@/utils/enums/names";
import { PRODUCT_TYPES } from "@/utils/enums";

export default defineComponent({
  emits: ['toggleProductCart'],
  name: 'CatalogProduct',
  data() {
    return {
      hover: false
    }
  },
  props: {
    product: {
      type: Object as PropType<Product.Dto>,
      required: true
    },
    cartProduct: {
      type: Object
    }
  },
  methods: {
    addToCart() {
      this.$emit('toggleProductCart', this.product._id, 1); 
    },
    toggleProductCart(inc: number) {
      this.$emit('toggleProductCart', this.product._id, inc);
    }
  },
  computed: {
    currentType() {
      return this.product.type === PRODUCT_TYPES.UNIT ? 1 : this.product.unitsPerPack;
    },
    currentPrice() {
      return this.product.type === PRODUCT_TYPES.UNIT ? this.product.pricePerUnit : this.product.pricePerPack;
    }
  },
  components: {
    EmptyImageIcon,
    ShopCartIcon,
    MinusIcon,
    PlusIcon
  }
})
